<script setup lang="ts">

</script>

<template>
	<svg width="50" height="33" viewBox="0 0 50 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M42.6154 23.2384C42.6154 22.7733 42.6154 22.3816 42.6154 21.9287C43.1375 21.9287 43.6353 21.9287 44.1331 21.9287C46.2821 21.941 47.047 21.1209 46.9134 18.881C45.7843 18.881 44.6551 18.881 43.4532 18.881C43.4532 18.0977 43.4532 17.3755 43.4532 16.6534C43.4532 15.9435 43.4532 15.2336 43.4532 14.4992C43.8903 14.4992 44.2909 14.4992 44.7401 14.4992C44.7401 15.4906 44.7401 16.482 44.7401 17.5102C45.4929 17.5102 46.1849 17.5102 46.9377 17.5102C46.9377 16.7635 46.962 16.0414 46.9255 15.3192C46.9134 15.1601 46.7313 14.9888 46.5856 14.8664C45.0558 13.5445 43.5139 12.247 41.9841 10.9251C41.7049 10.6804 41.4377 10.3866 41.2192 10.0806C40.0779 8.50167 38.9488 6.9105 37.8318 5.31933C36.1442 2.92033 33.8617 1.74531 30.9357 1.75755C22.6433 1.76979 14.3387 1.74531 6.03417 1.74531C3.80019 1.74531 2.5618 2.99377 2.5618 5.23365C2.5618 9.93373 2.5618 14.6216 2.5618 19.3216C2.5618 21.1209 3.35097 21.9165 5.16 21.9165C6.00988 21.9165 6.85976 21.9165 7.74607 21.9165C7.74607 22.3571 7.74607 22.761 7.74607 23.1894C6.59266 23.1894 5.41497 23.2996 4.28584 23.1649C2.53752 22.9446 1.2627 21.3412 1.2627 19.5542C1.2627 14.744 1.2627 9.94597 1.2627 5.13573C1.2627 2.46745 3.29027 0.423409 5.94918 0.423409C14.3387 0.423409 22.7282 0.411169 31.1299 0.423409C34.3109 0.435648 36.8727 1.76979 38.7667 4.35238C39.9322 5.94355 41.0371 7.55921 42.1905 9.16262C42.409 9.46862 42.6761 9.75013 42.9554 10.0072C44.6066 11.4392 46.2699 12.8468 47.9211 14.2789C48.0911 14.4257 48.2246 14.7317 48.2368 14.9643C48.2611 16.5187 48.2611 18.0732 48.2489 19.6276C48.2368 21.586 46.6099 23.2261 44.6794 23.2384C44.0117 23.2384 43.3439 23.2384 42.6154 23.2384Z" fill="white"/>
		<path d="M10.2107 25.4533C8.66878 22.8952 8.83875 20.8879 10.6721 19.3702C12.299 18.0238 14.4601 18.1095 16.0142 19.566C17.6896 21.1327 17.7989 23.2135 16.2691 25.3799C22.2183 25.3799 28.1432 25.3799 34.1288 25.3799C33.2425 24.2538 32.7811 23.0299 33.1575 21.61C33.4124 20.6186 33.9709 19.823 34.7844 19.2233C36.387 18.0238 38.4631 18.1707 39.9565 19.5782C41.5956 21.1327 41.6927 23.0666 40.1993 25.4044C43.4653 25.4044 46.6948 25.4044 49.9608 25.4044C49.9608 25.8695 49.9608 26.2734 49.9608 26.714C33.3153 26.714 16.6698 26.714 0 26.714C0 26.2979 0 25.9062 0 25.4533C3.3631 25.4533 6.75048 25.4533 10.2107 25.4533ZM16.0506 22.6137C16.0627 20.9491 14.8729 19.7374 13.2338 19.7251C11.6069 19.7251 10.405 20.9124 10.405 22.5403C10.3928 24.2049 11.5827 25.4166 13.2217 25.4289C14.8365 25.4289 16.0385 24.2416 16.0506 22.6137ZM37.1762 25.4289C38.791 25.4166 39.9808 24.2171 39.9808 22.5892C39.9808 20.9369 38.7788 19.7251 37.1276 19.7374C35.5128 19.7496 34.323 20.9491 34.323 22.577C34.323 24.2294 35.525 25.4411 37.1762 25.4289Z" fill="white"/>
		<path d="M26.0306 3.5333C26.0306 6.03022 26.0306 8.46594 26.0306 10.9384C19.0859 10.9384 12.1776 10.9384 5.17214 10.9384C5.17214 10.5589 5.17214 10.1918 5.17214 9.81232C5.17214 8.5761 5.16 7.32764 5.17214 6.09142C5.19643 4.74504 6.3984 3.52106 7.72178 3.52106C13.7438 3.50883 19.7537 3.52106 25.7757 3.52106C25.8607 3.50883 25.9335 3.52107 26.0306 3.5333ZM24.7315 4.8552C21.8177 4.8552 18.9523 4.8552 16.0992 4.8552C16.0992 6.47085 16.0992 8.04979 16.0992 9.61648C19.0009 9.61648 21.8541 9.61648 24.7315 9.61648C24.7315 8.02531 24.7315 6.45861 24.7315 4.8552ZM6.49553 9.60424C9.28799 9.60424 12.0076 9.60424 14.7151 9.60424C14.7151 7.98859 14.7151 6.40966 14.7151 4.81848C12.3476 4.81848 10.0407 4.80624 7.72178 4.81848C6.99332 4.81848 6.53195 5.24688 6.50767 5.95678C6.47125 7.16852 6.49553 8.36802 6.49553 9.60424Z" fill="white"/>
		<path d="M39.8109 10.9374C35.6222 10.9374 31.5428 10.9374 27.4148 10.9374C27.4148 8.48941 27.4148 6.04145 27.4148 3.50781C27.6576 3.50781 27.9247 3.50781 28.1918 3.50781C29.5152 3.53229 30.8386 3.47109 32.1498 3.61797C33.801 3.80157 35.1608 4.63387 36.1686 5.96801C37.3827 7.57142 38.5482 9.21156 39.8109 10.9374ZM37.2248 9.62771C36.5814 8.74644 36.0229 7.93862 35.4279 7.16751C35.0759 6.71464 34.6995 6.24953 34.2624 5.88233C32.6112 4.47476 30.6201 4.86643 28.7139 4.82971C28.7139 6.46984 28.7139 8.04878 28.7139 9.62771C31.5428 9.62771 34.3231 9.62771 37.2248 9.62771Z" fill="white"/>
		<path d="M31.2635 22.3945C31.2635 22.8229 31.2635 23.2268 31.2635 23.643C27.0748 23.643 22.9225 23.643 18.7217 23.643C18.7217 23.2513 18.7217 22.8352 18.7217 22.3945C22.874 22.3945 27.0384 22.3945 31.2635 22.3945Z" fill="white"/>
		<path d="M20.4336 14.4276C20.4336 14.0115 20.4336 13.6076 20.4336 13.1914C22.0119 13.1914 23.5782 13.1914 25.1686 13.1914C25.1686 13.6076 25.1686 13.9992 25.1686 14.4276C23.6146 14.4276 22.0484 14.4276 20.4336 14.4276Z" fill="white"/>
		<path d="M32.5625 13.1758C32.5625 13.5919 32.5625 13.9958 32.5625 14.4242C30.9963 14.4242 29.4301 14.4242 27.8396 14.4242C27.8396 14.0203 27.8396 13.6164 27.8396 13.1758C29.4179 13.1758 30.972 13.1758 32.5625 13.1758Z" fill="white"/>
		<path d="M8.18305 32.4203C7.60027 32.4203 7.06606 32.4203 6.50757 32.4203C6.50757 32.0042 6.50757 31.6125 6.50757 31.1719C7.04178 31.1719 7.60027 31.1719 8.18305 31.1719C8.18305 31.5758 8.18305 31.9797 8.18305 32.4203Z" fill="white"/>
		<path d="M9.99219 32.4203C9.99219 32.0042 9.99219 31.6125 9.99219 31.1719C10.5385 31.1719 11.0849 31.1719 11.6677 31.1719C11.6677 31.5635 11.6677 31.9797 11.6677 32.4203C11.1213 32.4203 10.575 32.4203 9.99219 32.4203Z" fill="white"/>
		<path d="M15.1642 31.1719C15.1642 31.6125 15.1642 32.0042 15.1642 32.4203C14.6058 32.4203 14.0715 32.4203 13.4888 32.4203C13.4888 32.0164 13.4888 31.6125 13.4888 31.1719C14.023 31.1719 14.5693 31.1719 15.1642 31.1719Z" fill="white"/>
		<path d="M18.6488 31.1719C18.6488 31.588 18.6488 31.9919 18.6488 32.4081C18.0782 32.4081 17.5318 32.4081 16.9612 32.4081C16.9612 31.9919 16.9612 31.6003 16.9612 31.1719C17.4954 31.1719 18.0417 31.1719 18.6488 31.1719Z" fill="white"/>
		<path d="M20.4336 31.1562C20.9921 31.1562 21.5263 31.1562 22.0969 31.1562C22.0969 31.5602 22.0969 31.9641 22.0969 32.4047C21.5627 32.4047 21.0042 32.4047 20.4336 32.4047C20.4336 32.0008 20.4336 31.5969 20.4336 31.1562Z" fill="white"/>
		<path d="M25.5935 32.4203C25.035 32.4203 24.5008 32.4203 23.9302 32.4203C23.9302 32.0164 23.9302 31.6125 23.9302 31.1719C24.4644 31.1719 25.0229 31.1719 25.5935 31.1719C25.5935 31.588 25.5935 31.9919 25.5935 32.4203Z" fill="white"/>
		<path d="M29.0781 32.4203C28.5196 32.4203 27.9854 32.4203 27.4148 32.4203C27.4148 32.0164 27.4148 31.6125 27.4148 31.1719C27.949 31.1719 28.5075 31.1719 29.0781 31.1719C29.0781 31.588 29.0781 31.9919 29.0781 32.4203Z" fill="white"/>
		<path d="M32.5503 32.4203C31.9918 32.4203 31.4576 32.4203 30.887 32.4203C30.887 32.0164 30.887 31.6125 30.887 31.1719C31.4212 31.1719 31.9797 31.1719 32.5503 31.1719C32.5503 31.588 32.5503 31.9919 32.5503 32.4203Z" fill="white"/>
		<path d="M36.0349 32.4203C35.4764 32.4203 34.9422 32.4203 34.3716 32.4203C34.3716 32.0164 34.3716 31.6125 34.3716 31.1719C34.9058 31.1719 35.4643 31.1719 36.0349 31.1719C36.0349 31.588 36.0349 31.9919 36.0349 32.4203Z" fill="white"/>
		<path d="M37.8318 31.1562C38.4024 31.1562 38.9366 31.1562 39.5073 31.1562C39.5073 31.5724 39.5073 31.9763 39.5073 32.4169C38.9488 32.4169 38.4024 32.4169 37.8318 32.4169C37.8318 32.0008 37.8318 31.6091 37.8318 31.1562Z" fill="white"/>
		<path d="M43.0038 32.4169C42.4454 32.4169 41.9111 32.4169 41.3284 32.4169C41.3284 32.013 41.3284 31.5969 41.3284 31.1562C41.8747 31.1562 42.4211 31.1562 43.0038 31.1562C43.0038 31.5724 43.0038 31.9641 43.0038 32.4169Z" fill="white"/>
		<path d="M13.4281 21.4885C14.1444 21.4762 14.7515 22.0637 14.7515 22.7859C14.7636 23.508 14.1687 24.1078 13.4402 24.1078C12.736 24.1078 12.1533 23.5325 12.1411 22.8348C12.1168 22.1127 12.6996 21.5007 13.4281 21.4885Z" fill="white"/>
		<path d="M37.3948 21.4885C38.1111 21.5007 38.6939 22.1127 38.6696 22.8348C38.6453 23.557 38.0383 24.1445 37.3098 24.1078C36.6178 24.0833 36.0471 23.4836 36.0593 22.7859C36.0714 22.0637 36.6785 21.4762 37.3948 21.4885Z" fill="white"/>
	</svg>
</template>

<style scoped lang="scss">

</style>