<script setup lang="ts">

</script>

<template>
	<svg width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M29.4613 21.2819C30.078 19.7841 30.4128 18.1807 30.4128 16.4186C30.4128 15.15 30.219 13.9342 29.8842 12.8065C28.7389 13.0708 27.5407 13.2117 26.2897 13.2117C23.7276 13.2145 21.2024 12.6012 18.927 11.4236C16.6516 10.2461 14.6927 8.53871 13.2153 6.44548C11.6364 10.2704 8.65465 13.3468 4.88085 15.0442C4.81037 15.4848 4.81037 15.9605 4.81037 16.4186C4.81037 18.1009 5.14171 19.7666 5.78548 21.3208C6.42924 22.875 7.37282 24.2872 8.56235 25.4767C10.9647 27.8791 14.223 29.2287 17.6204 29.2287C19.4706 29.2287 21.2502 28.8234 22.8537 28.101C23.858 30.0216 24.3162 30.9731 24.2809 30.9731C21.3912 31.9422 19.1534 32.418 17.6204 32.418C13.3563 32.418 9.28596 30.744 6.29049 27.7309C4.46686 25.9159 3.11224 23.6844 2.34351 21.229H0V13.2117H1.92062C2.50252 10.3793 3.84036 7.75716 5.79216 5.62365C7.74397 3.49014 10.2369 1.9248 13.0066 1.09374C15.7762 0.262674 18.7191 0.196884 21.5231 0.903347C24.3271 1.60981 26.8875 3.06218 28.9327 5.10633C31.1525 7.31871 32.667 10.1392 33.285 13.2117H35.2408V21.229H35.1351L28.8622 26.9909L19.5234 25.9337V22.991H28.0341L29.4613 21.2819ZM12.81 16.0134C13.3387 16.0134 13.8496 16.2248 14.2197 16.6125C14.5915 16.9874 14.8002 17.494 14.8002 18.0221C14.8002 18.5502 14.5915 19.0568 14.2197 19.4317C13.8496 19.8018 13.3387 20.0132 12.81 20.0132C11.7 20.0132 10.8013 19.1322 10.8013 18.0221C10.8013 16.912 11.7 16.0134 12.81 16.0134ZM22.4132 16.0134C23.5233 16.0134 24.4043 16.912 24.4043 18.0221C24.4043 19.1322 23.5233 20.0132 22.4132 20.0132C21.3031 20.0132 20.4044 19.1322 20.4044 18.0221C20.4044 17.4893 20.6161 16.9784 20.9928 16.6017C21.3695 16.225 21.8804 16.0134 22.4132 16.0134Z" fill="white"/>
	</svg>
</template>

<style scoped lang="scss">

</style>