<script setup lang="ts">

</script>

<template>
	<div class="loader">
		<v-progress-circular indeterminate color="rgb(112, 89, 255)"/>
	</div>
</template>

<style scoped lang="scss">
	.loader {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 300px;
	}

</style>